@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
/* Google fonts */

/* Font Awesome */

:root {
  --light-grey: #f5f5f5;
  --deep-blue: #247afe;
  --washed-out-blue: #3787ff;
  --mustard-yellow: #f5a101;
  --gumby-green: #01ac6a;
  --ketchup-red: #e81500;
  --dark-grey: #243036;
  --light-grey-2: #c4c7cb;
  --medium-grey: #8f959d;
  --blue-grey: #aeaeba;
  --border-light-grey: #dcdcdc;
  
  /* Dashboard variables */
  --light-grey-3: #e8e8e8;
  --grey: #646464;
  --lime-green: #4ae44a;
  --red: #e45e4a;
  --yellow: #e4df4a;
  --white: #ffffff;
  --light-grey-shadow: #d9d9d9;
  --purple: #a84ae4;
  --white-shadow: #e5e5e5;
  --orange: #e4b34a;
  --cyan: #4adfe4;
  --blue: #3788f7;
  --black: #000000;
  --green: #1fd81f;
  --dark-grey-2: #151515;
  --font-size: 38px;
}

/* Generic styling */
html, body {
  background-color: white;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
}

a, a:hover, a:focus, a:active {
  color: inherit;
  text-decoration: none;
}

input[type="date"] {
  font-family: "Open Sans", sans-serif;
}

/* Header */
#header-container {
  align-items: center;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: var(--dark-grey);
  display: flex;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 15px 14px 25px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

#header-container i {
  color: var(--blue-grey);
  cursor: pointer;
  font-size: 22px;
  margin-right: 15px;
}

/* --> Logout/Navigator menu */
#logout-menu-container, #nav-menu-container, #edit-menu-container {
  background-color: white;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: 400;
  padding: 5px 0 5px 0;
  position: absolute;
  right: 0;
  width: 300px;
}

#logout-menu-container div, #nav-menu-container div, #edit-menu-container div {
  cursor: pointer;
  padding: 2px 15px 2px 15px;
  width: calc(100% - 30px);
}
#logout-menu-container div:hover, #nav-menu-container div:hover, #edit-menu-container div:hover {
  background-color: var(--washed-out-blue);
  color: white;
}

/* --> Search bar */
#header-search-bar-container {
  align-items: center;
  background-color: var(--light-grey);
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  margin: -5px 0 -5px 15px;
  overflow: hidden;
  padding: 5px 10px 5px 10px;
}

#header-search-bar-container i {
  color: var(--medium-grey);
  font-size: 16px;
  margin-right: 5px;
}

#header-search-bar-container input {
  background-color: var(--light-grey);
  border: none;
  color: var(--medium-grey);
  font-size: 16px;
  width: 100%;
}
#header-search-bar-container input:focus {
  outline: none;
}

#header-search-bar-container input::-webkit-input-placeholder {
  color: var(--medium-grey);
}

#header-search-bar-container input:-ms-input-placeholder {
  color: var(--medium-grey);
}

#header-search-bar-container input::placeholder {
  color: var(--medium-grey);
}

@media screen and (max-width: 850px) {
  #header-search-bar-container {
      display: none;
  }

  .content-table {
    min-width: 900px;
  }

  .status-boards-table {
    min-width: 900px;
  }

  #sb-content-table-container {
    min-width: 900px;
  }
  
  .content-container {
    overflow: scroll;
  }
}

/* --> Modal */
.modal-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: var(--medium-grey);
  font-size: 18px;
  font-weight: 400;
  left: 50%;
  padding: 20px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 425px;
  z-index: 100;
}

.stable-modal-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: var(--medium-grey);
  font-size: 18px;
  font-weight: 400;
  padding: 20px;
  position: fixed;
  width: 425px;
  z-index: 100;
}

.pos-modal-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: var(--medium-grey);
  font-size: 18px;
  font-weight: 400;
  padding: 20px;
  position: fixed;
  width: 425px;
  z-index: 100;
}

.modal-title {
  color: var(--dark-grey);
  font-size: 20px;
  font-weight: 600;
}

.modal-exit-container {
  margin-left: auto;
}

.modal-exit-container i {
  bottom: 7px;
  color: var(--ketchup-red);
  cursor: pointer;
  font-size: 22px;
  position: relative;
}

.modal-container .content-text {
  width: 100%;
}

.stable-modal-container .content-text {
  width: 100%;
}

/* --> Footer button */
#footer-button {
  align-items: center;
  background-color: white;
  border-radius: 100px;
  bottom: 25px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: var(--dark-grey);
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding: 8px 14px 8px 14px;
  position: fixed;
  right: 25px;
}

#footer-button i {
  color: var(--deep-blue);
  font-size: 25px;
  margin-right: 7px;
}

/* Login container */
#login-container {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: var(--dark-grey);
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 40px 30px 45px 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 330px;
}

#login-container input {
  background-color: #f0f0f0;
  border: none;
  box-sizing: border-box;
  border-radius: 100px;
  color: var(--dark-grey);
  font-size: 16px;
  margin-bottom: 12px;
  padding: 7px 15px 7px 15px;
  width: 100%;
}

#login-container input::-webkit-input-placeholder {
  color: #858585;
}

#login-container input:-ms-input-placeholder {
  color: #858585;
}

#login-container input::placeholder {
  color: #858585;
}

#login-container input:focus {
  outline: none;
}

.login-button {
  align-items: center;
  background-color: var(--deep-blue);
  border: none;
  border-radius: 100px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: flex;
  /* font-weight: 600; */
  justify-content: center;
  margin-bottom: 20px;
  padding: 7px 0 7px 0;
  width: 100%;
}
.login-button:hover {
  -webkit-filter: brightness(1.15);
          filter: brightness(1.15);
}

.login-error-text {
  color: red;
  font-size: 16px;
  margin: -5px 0 10px 0;
  width: 250px;
  text-align: center;
}

/* Background */
#background {
  background-color: var(--light-grey);
  background-image: url(/static/media/background.77732f84.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

#background-filter {
  background-color: var(--deep-blue);
  height: 100%;
  opacity: 0.6;
  width: 100%;
}

/* Apps portal */
#apps-portal-container {
  align-items: center;
  display: flex;
  height: calc(100% - 50px);
  justify-content: center;
  position: absolute;
  top: 50px;
  width: 100%;
}

.app-container {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.07);
  height: 45vh;
  overflow: hidden;
}

.app-cover {
  background-color: var(--deep-blue);
  cursor: pointer;
  height: 100%;
  opacity: 0.15;
  width: 100%;
}
.app-cover:hover {
  opacity: 0;
}

.app-text-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 325px;
}

.app-title {
  color: white;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
}

.app-subtitle {
  color: white;
  text-align: center;
  width: 225px;
}

/* "No Match" page */
#no-match-text {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: var(--dark-grey);
  display: flex;
  font-size: 20px;
  justify-content: center;
  left: 50%;
  padding: 20px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#no-match-text i {
  color: var(--deep-blue);
  font-size: 25px;
  margin-right: 7px;
}

/* Mark.js */
mark {
  background-color: yellow;
  padding: 0;
  margin: 0;
}

/* Side navigation bar */
#side-nav {
  background-color: white;
  border-right: 1px solid var(--border-light-grey);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  height: 100vh;
  min-height: 335px;
  padding: 30px 0 0 0;
  position: fixed;
  width: 250px;
  z-index: -1;
}

#side-nav-collapsed {
  background-color: white;
  border-right: 1px solid var(--border-light-grey);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 35px;
  height: 100vh;
  min-height: 335px;
  position: fixed;
  width: 70px;
  z-index: -1;
  color: var(--deep-blue);
  align-items: center;
  margin-top: -10px;
}

#collapse-icon {
  left: 58px;
  display: flex;
}

.side-nav-link {
  align-items: center;
  box-sizing: border-box;
  color: #787878;
  cursor: pointer;
  display: flex;
  font-size: 17px;
  padding: 5px 0 5px 25px;
}

.side-nav-link .fa-angle-right {
  font-size: 20px;
  margin-left: auto;
}

.side-nav-title {
  color: var(--dark-grey);
  font-weight: 600;
  margin: 0 0 0 25px;
}

.side-nav-title i {
  color: var(--deep-blue);
  font-size: 20px;
  margin-right: 7px;
}

.active-nav-link div {
  background-color: #d3e5ff;
  color: var(--deep-blue);
}

/* Side navigation bar (Purchasing) */
#purchasing-side-nav {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  height: calc(100vh - 95px);
  left: 20px;
  min-height: 335px;
  padding: 20px 0 20px 0;
  position: absolute;
  top: 75px;
  width: 300px;
}

.purchasing-side-nav-link {
  align-items: center;
  box-sizing: border-box;
  color: var(--medium-grey);
  cursor: pointer;
  display: flex;
  padding: 5px 25px 5px 25px;
}

.purchasing-side-nav-link .fa-angle-right {
  font-size: 20px;
  margin-left: auto;
}

.purchasing-side-nav-title {
  color: var(--dark-grey);
  font-weight: 600;
  margin: 0 25px 5px 25px;
}

.purchasing-side-nav-title i {
  font-size: 20px;
  margin-right: 7px;
}

.purchasing-active-nav-link div {
  background-color: var(--washed-out-blue);
  color: white;
}

/* Quick actions navigator */
.quick-action-button {
  border-radius: 100px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 8px 15px;
  margin-bottom: 10px;
  margin-right: 15px;
}
.quick-action-button:hover {
  -webkit-filter: brightness(1.15);
          filter: brightness(1.15);
}

/* Content container */
#outer-content-container {
  position: absolute;
  right: 0;
  width: calc(100% - 70px);
}

#outer-content-title {
  color: white;
  font-size: 35px;
  font-weight: 600;
  margin: 30px 0 5px 25px;
  min-width: 300px;
}

#outer-content-subtitle {
  color: white;
  font-size: 20px;
  margin: 0 0 45px 25px;
  min-width: 275px;
}

/* Inner content */
.content-container {
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  min-width: 350px;
  padding: 30px;
  overflow: auto;
  width: 100%;
}

.content-container-shadow {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  margin-bottom: 20px;
  min-width: 350px;
  padding: 20px;
  overflow: hidden;
  width: 100%;
}

.content-title {
  align-items: center;
  color: var(--dark-grey);
  display: flex;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.content-title i {
  color: var(--deep-blue);
  margin-right: 7px;
}

.content-subtitle {
  bottom: 10px;
  color: #787878;
  font-size: 18px;
  position: relative;
  margin-bottom: 5px;
}

.content-subtitle i {
  color: var(--deep-blue);
  margin-right: 10px;
}

.content-text {
  align-items: center;
  color: var(--medium-grey);
  display: flex;
  font-size: 17px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.content-text span {
  display: inline-block;
  margin-right: 10px;
}

/* --> Inbox icon */
.ph-inbox-icon {
  align-items: center;
  color: white;
  display: flex;
  font-size: 22px;
  position: absolute;
  right: 20px;
  top: 61px;
}

.ph-inbox-icon span {
  font-size: 20px;
  font-weight: "bold";
  margin-right: 10px;
}

/* --> Input */
.content-text input {
  border: 1px solid var(--medium-grey);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--dark-grey);
  flex-grow: 1;
  font-size: 17px;
  min-width: 100px;
  padding: 4px 7px 4px 7px;
  font-size: 17px;
  border: 1px solid #b0b0b0;
  color: #808080;
}
.content-text input:focus {
  outline: none;
}

.content-text-input {
  border: 1px solid var(--medium-grey);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--dark-grey);
  flex-grow: 1;
  font-size: 17px;
  min-width: 100px;
  padding: 4px 7px 4px 7px;
  font-size: 17px;
  border: 1px solid #b0b0b0;
  color: #808080;
}
.content-text-input:focus {
  outline: none;
}

/* Text Area */
.content-text textarea {
  border: 1px solid var(--medium-grey);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--dark-grey);
  font-family: "Open Sans", sans-serif;
  flex-grow: 1;
  font-size: 15px;
  min-width: 100px;
  padding: 4px 7px 4px 7px;
  font-size: 17px;
  border: 1px solid #b0b0b0;
  color: #808080;
  resize: none;
}
.content-text textarea:focus {
  outline: none;
}

/* --> Select */
.content-text select {
  border-radius: 9px;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 15px;
  padding: 5px;
  border: 1px solid #b0b0b0;
  color: #808080;
  background-color: white;
}
.content-text select:focus {
  outline: none;
}

.content-error-text {
  color: red;
  margin-top: 15px;
}

/* --> Text area */
.content-text-area {
  border: 1px solid var(--medium-grey);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--dark-grey);
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  margin-bottom: 15px;
  min-height: 175px;
  padding: 10px;
  resize: none;
  width: 100%;
}

.content-text-area:focus {
  outline: none;
}

.content-text-area::-webkit-scrollbar {
  display: none;
}

/* --> Table */
.content-table-container {
  border: 1px solid var(--border-light-grey);
  border-collapse: collapse;
  border-radius: 7px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

#sb-content-table-container {
  border: 1px solid var(--border-light-grey);
  border-collapse: collapse;
  border-radius: 7px;
  max-height: none;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

#sb-content-table-container th {
  background-color: #f5f5f5;
  border-bottom: 1px solid var(--border-light-grey);
  font-size: 15px;
  padding: 10px;
}

/* .content-table tr:nth-child(even), #sb-content-table-container tr:nth-child(even) {
  background-color: #f5f5f5;
}

.content-table tr:nth-child(odd), #sb-content-table-container tr:nth-child(odd) {
  background-color: white;
} */

.content-table tr, #sb-content-table-container tr {
  background-color: white;
}

.content-table {
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden;
  table-layout: fixed;
  width: 100%;
}

.content-table tr {
  border-bottom: 1px solid var(--border-light-grey);
}

.content-table th {
  background-color: #f5f5f5;
  border-bottom: 1px solid var(--border-light-grey);
  color: var(--dark-grey);
  font-size: 15px;
  font-weight: 600;
  padding: 10px 15px;
  text-align: center;
  top: 0px;
  text-align: left;
}

.content-table td {
  color: #787878;
  border: none;
  font-size: 16px;
  height: 35px;
  padding-left: 15px;
}

.ct-nonselectable td {
  cursor: default;
}

.content-table tbody tr {
  cursor: pointer;
}

.content-table-button {
  background-color: var(--deep-blue);
  border-radius: 100px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 3px 0 3px 0;
  padding: 3px 18px 3px 18px;
}
.content-table-button:hover {
  -webkit-filter: brightness(1.15);
          filter: brightness(1.15);
}

.process-table {
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden;
  table-layout: fixed;
  width: 100%;
}

.process-table tr {
  border-bottom: 1px solid var(--border-light-grey);
}

.process-table th {
  background-color: #f5f5f5;
  border-bottom: 1px solid var(--border-light-grey);
  color: var(--dark-grey);
  font-size: 15px;
  font-weight: 600;
  padding: 10px 15px;
  text-align: center;
  top: 0px;
  text-align: left;
}

.process-table td {
  color: #787878;
  border: none;
  font-size: 16px;
  height: 35px;
  padding-left: 15px;
}

.process-table tbody tr {
  cursor: pointer;
}

/* --> Static table header */
#static-table-header {
  display: none;
  position: fixed;
  top: 94px;
  z-index: 10;
  width: calc(100% - 130px);
  background-color: #3787ff;
  flex-wrap: nowrap;
  margin: 0 30px 0 30px;
  border-left: 1px solid var(--border-light-grey);
  border-right: 1px solid var(--border-light-grey);
  align-items: stretch;
}

#static-table-header div {
  background-color: #f5f5f5;
  border-bottom: 1px solid var(--border-light-grey);
  color: var(--dark-grey);
  font-size: 15px;
  font-weight: 600;
  padding: 10px 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1 1;
}

/* --> Button */
.content-button {
  background-color: var(--deep-blue);
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: inline-block;
  margin: 0 0 20px 0;
  font-size: 16px;
  padding: 8px 17px 8px 17px;
}
.content-button:hover {
  -webkit-filter: brightness(1.07);
          filter: brightness(1.07);
}

/* --> Search bar */
.content-search-bar-container {
  align-items: center;
  /* background-color: #f1f1f1; */
  border: 1px solid var(--light-grey-2);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  padding: 6px 12px 6px 12px;
  overflow: hidden;
  height: 35px;
}

.content-search-bar-container i {
  color: #9a9a9a;
  font-size: 17px;
  margin-right: 5px;
}

.content-search-bar-container input {
  /* background-color: #f1f1f1; */
  border: none;
  color: #9a9a9a;
  font-size: 17px;
  width: 100%;
  position: relative;
  top: 1px;
}
.content-search-bar-container input:focus {
  outline: none;
}

.content-search-bar-container input::-webkit-input-placeholder {
  color: #9a9a9a;
}

.content-search-bar-container input:-ms-input-placeholder {
  color: #9a9a9a;
}

.content-search-bar-container input::placeholder {
  color: #9a9a9a;
}

#sb-search-clear {
  color: var(--medium-grey);
  cursor: pointer;
  position: relative;
  top: 1px;
  left: 5px;
}
#sb-search-clear i {
  font-size: 18px;
}

/* Status Boards page */
/* --> Filter */
#sb-filter-container {
  display: flex;
  position: relative;
  top: -1px;
}

.sb-filter-select {
  font-size: 18px;
  margin-right: 20px;
  background-color: white;
}

.sb-filter-select #board-select {
  height: 35px;
}

.sb-filter-select div {
  color: var(--dark-grey);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 11px;
}

.sb-filter-select div i {
  margin-right: 7px;
}

.sb-filter-select select {
  border-color: var(--medium-grey);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--dark-grey);
  font-size: 16px;
  padding: 4px 6px 4px 6px;
}

.sb-filter-select select:focus {
  outline: none;
}

#sb-action-text {
  color: var(--medium-grey);
  margin-top: 10px;
}

#sb-action-text span {
  cursor: pointer;
}

/* --> Table */
.status-boards-table {
  table-layout: fixed;
}

.status-boards-table td {
  border-right: 1px solid var(--border-light-grey);
  cursor: default;
}

.status-boards-table th {
  text-align: center;
  cursor: pointer;
}

.settings-table {
  table-layout: fixed;
}

.settings-table td {
  border-right: 1px solid var(--border-light-grey);
  cursor: pointer;
}

.settings-table th {
  text-align: center;
  cursor: default;
}

.sb-table-title td {
  color: var(--dark-grey);
  font-weight: 600;
}

.sb-split-marker {
  background-color: #ff0000;
  float: right;
  height: 100%;
  position: relative;
  left: 35px;
  width: 70px;
  z-index: 1;
}

.marker-info-icon {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  position: absolute;
  top: 0;
  right: 0;
}

/* --> Actions menu */
#sb-actions-container i {
  color: #c0c0c0;
  cursor: pointer;
  position: relative;
  left: 1px;
}

.sb-action-icon {
  color: var(--washed-out-blue);
}
.sb-action-icon:hover {
  -webkit-filter: brightness(1.15);
          filter: brightness(1.15);
}

.sb-horiz {
  flex-direction: inherit;
  left: 25px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  padding: 8px 12px 8px 0;
  
  align-items: center;
  background-color: white;
  bottom: 25px;
  border-radius: 100px;
  box-sizing: border-box;
  color: var(--blue-grey);
  display: flex;
  font-size: 30px;
  position: fixed;
  padding: 8px 0 8px 12px;
}

.sb-horiz i {
  margin: 0 0 0 0;
}

.sb-vert {
  flex-direction: column;
  align-items: center;
  left: 6px;
  box-shadow: none;
  padding: 0 0 0 0;
  justify-content: center;

  align-items: center;
  background-color: white;
  bottom: 25px;
  border-radius: 100px;
  box-sizing: border-box;
  color: var(--blue-grey);
  display: flex;
  font-size: 30px;
  position: fixed;
  padding: 8px 0 8px 12px;
}

.sb-vert i {
  margin: 12px 0 0 0;
}

/* --> Group chat container */
.sb-modal-chat-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;
  font-size: 17px;
  max-height: none;
  overflow-y: auto;
  width: 100%;
}

.sb-chat-header {
  color: #787878;
  font-size: 14px;
  margin-bottom: 6px;
}

.sb-chat-message {
  background-color: #f2f2f2;
  border-radius: 10px;
  box-sizing: border-box;
  color: #707070;
  margin-bottom: 15px;
  margin-top: 2px;
  margin-right: 20px;
  padding: 6px 12px 6px 12px;
}

.sb-chat-input-container {
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 15px;
  box-sizing: border-box;
  color: var(--dark-grey);
  display: flex;
  flex-direction: row;
  font-size: 17px;
  padding: 5px 10px 5px 10px;
  width: 100%;
}

.sb-chat-input-container input {
  background-color: #f0f0f0;
  border: none;
  color: #707070;
  font-size: 17px;
  width: 100%; 
}
.sb-chat-input-container input:focus {
  outline: none;
}
.sb-chat-input-container input::-webkit-input-placeholder {
  color: #707070;
}
.sb-chat-input-container input:-ms-input-placeholder {
  color: #707070;
}
.sb-chat-input-container input::placeholder {
  color: #707070;
}

.sb-chat-icon {
  color: var(--washed-out-blue);
  cursor: pointer;
  font-size: 25px;
  margin-left: 8px;
}
.sb-chat-icon:hover {
  -webkit-filter: brightness(1.15);
          filter: brightness(1.15);
}

/* Location strip */
.location-strip-container {
  align-items: center;
  background-color: #7B9FBC;
  border-bottom: 1px solid var(--border-light-grey);
  box-sizing: border-box;
  color: white;
  display: flex;
  font-size: 15px;
  padding: 10px 15px 10px 15px;
  z-index: 0;
}

.fixed-location-strip-container {
  align-items: center;
  background-color: #7B9FBC;
  border-bottom: 1px solid var(--border-light-grey);
  box-sizing: border-box;
  color: white;
  display: flex;
  font-size: 15px;
  padding: 10px 15px 10px 15px;
  z-index: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.pulsing-location-strip-effect {
  animation: pulsing-location-strip 3s infinite;
  -webkit-animation: pulsing-location-strip 3s infinite;
}

@-webkit-keyframes pulsing-location-strip {
  0% {
    background-color: #7c9ebb;
  }
  50% {
    background-color: #3787ff;
  }
  100% {
    background-color: #7c9ebb;
  }
}

@keyframes pulsing-location-strip {
  0% {
    background-color: #7c9ebb;
  }
  50% {
    background-color: #3787ff;
  }
  100% {
    background-color: #7c9ebb;
  }
}

.location-strip-container i {
  font-size: 14px;
  margin: 0 10px 0 10px;
}

.location-strip-icon {
  margin-left: auto;
}

.location-strip-icon i {
  cursor: pointer;
  font-size: 22px;
  margin-left: 10px;
}

/* Prevent default click behavior */
.prevent-click {
  pointer-events: none;
}

/* Table input */
.table-input {
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}

.ti-title {
  background-color: #e2e2e2;
  padding: 5px 10px 5px 10px;
  width: calc(100% - 20px);
}

.ti-row {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.ti-inner-container {
  padding: 15px;
}

#table-input-error {
  margin-bottom: 15px;
  color: #ff0000;
  display: none;
}

.table-input input {
  border: 1px solid var(--medium-grey);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--dark-grey);
  flex-grow: 1;
  font-size: 17px;
  min-width: 100px;
  padding: 4px 7px 4px 7px;
}
.table-input input:focus {
  outline: none;
}

.ti-icons-container {
  display: flex;
  align-items: center;
  margin-left: 3px;
  font-size: 20px;
}

.ti-icons-container i {
  margin-left: 7px;
  cursor: pointer;
}

.ti-scroll-container {
  max-height: 120px;
  overflow-y: auto;
}

/* Full screen SB */
#fssb-outer-container {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

#fssb-content-table-container {
  border: 1px solid var(--border-light-grey);
  border-collapse: collapse;
  border-radius: 7px;
  max-height: none;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

#fssb-content-table-container th {
  background-color: #f5f5f5;
  border-bottom: 1px solid var(--border-light-grey);
  font-size: 15px;
  padding: 10px;
}

/* #fssb-content-table-container tr:nth-child(even) {
  background-color: #f5f5f5;
}

#fssb-content-table-container tr:nth-child(odd) {
  background-color: white;
} */

#fssb-content-table-container tr {
  background-color: white;
}

.table-arrow-icon {
  color: #ffffff;
  font-size: 20px;
  opacity: 0;
}

.table-arrow-icon:hover {
  opacity: 0.6;
}

.hide-ta-icon {
  display: none;
}

/* Logo */
#header-logo-container img {
  height: 25px;
  margin-right: 10px;
}

#nav-icon {
  display: none;
}

/* Process Manager */
.pm-property-container {
  margin: 0 0 10px 15px;
  display: flex;
  align-items: center;
}

.pm-property-container select {
  border-radius: 9px;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 15px;
  padding: 5px;
  border: 1px solid #b0b0b0;
  color: #808080;
  width: 100%;
  margin: 0 5px 0 5px;
  background-color: white;
}

.pm-property-container input {
  border-radius: 9px;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 15px;
  padding: 5px;
  border: 1px solid #b0b0b0;
  color: #808080;
  width: 100%;
  margin: 0 5px 0 5px;
}

.pm-caret {
  margin-left: 5px;
  cursor: pointer;
}

.pm-process-banner {
  width: 100%;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px 10px 15px;
}

.pm-error-text {
  color: #ff0000;
  font-size: 16px;
  margin-top: 10px;
}

.component-board-header-button-container {
  z-index: 100;
  position: fixed;
  height: 40px;
  display: flex;
  right: 0;
  align-items: center;
}

.component-board-header-button {
  background-color: none;
  border: 1px solid white;
  margin-right: 10px;
  padding: 3px 10px;
  border-radius: 4px;
  color: white;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}

/* Schedule Picker */
#schedule-picker-container {
  position: fixed;
  z-index: 9;
  background-color: rgba(254, 254, 254, 0.93);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#schedule-picker {
  width: calc(100% - 250px);
}

/* Media queries */
@media (max-width: 500px) {
  #side-nav {
    display: none;
  }

  #outer-content-container {
    position: inherit;
    left: 0;
    right: inherit;
    width: 100vw;
  }

  #expand-icon {
    display: none;
  }

  #sb-actions-container {
    display: none;
  }

  #header-logo-container img {
    height: 35px;
    margin-left: -5px;
  }

  #header-logo-container div {
    display: none;
  }

  .modal-container {
    width: 80%;
  }

  .stable-modal-container {
    width: 80%;
  }

  #collapse-icon {
    background-color: #ff0000;
    display: none;
  }

  #nav-icon {
    display: inherit;
  }

  #settings-icon {
    display: none;
  }
}

