/* Google fonts */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

/* Font Awesome */
@import url("../node_modules/@fortawesome/fontawesome-free/css/all.css");

:root {
  --light-grey: #f5f5f5;
  --deep-blue: #247afe;
  --washed-out-blue: #3787ff;
  --mustard-yellow: #f5a101;
  --gumby-green: #01ac6a;
  --ketchup-red: #e81500;
  --dark-grey: #243036;
  --light-grey-2: #c4c7cb;
  --medium-grey: #8f959d;
  --blue-grey: #aeaeba;
  --border-light-grey: #dcdcdc;
  
  /* Dashboard variables */
  --light-grey-3: #e8e8e8;
  --grey: #646464;
  --lime-green: #4ae44a;
  --red: #e45e4a;
  --yellow: #e4df4a;
  --white: #ffffff;
  --light-grey-shadow: #d9d9d9;
  --purple: #a84ae4;
  --white-shadow: #e5e5e5;
  --orange: #e4b34a;
  --cyan: #4adfe4;
  --blue: #3788f7;
  --black: #000000;
  --green: #1fd81f;
  --dark-grey-2: #151515;
  --font-size: 38px;
}

/* Generic styling */
html, body {
  background-color: white;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
}

a, a:hover, a:focus, a:active {
  color: inherit;
  text-decoration: none;
}

input[type="date"] {
  font-family: "Open Sans", sans-serif;
}
